import {
  DropzoneErrorCode,
  DropzoneErrorMessages,
  DropzoneMIMEType,
  DropzoneValidations,
  UseDropzoneProps,
  FileWithPath,
} from "@akinoxsolutions/gerudo-ui/dist/Dropzone"
import { TranslationFn } from "../../../hooks/useTranslation"
import { ShowUploadListType, UploadOptionsInterface } from "../UploadOptionsInterface"
import IconWrapper from "@akinoxsolutions/gerudo-ui/dist/Icons/IconWrapper/IconWrapper"
import React from "react"
import { UploadPropsInterface } from "../Upload"
import { DropzonePreviewAction } from "./types"

const nonPreviewableMimeTypes = [DropzoneMIMEType.Heic, DropzoneMIMEType.Tiff]

const getFileType = (file: unknown): string => {
  return file && typeof file === "object" && "type" in file && typeof file.type === "string" ? file.type : ""
}

export const fetchSuccessOrThrow: typeof fetch = async (...args) => {
  const response = await fetch(...args)

  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`)
  }

  return response
}

/** Returns whether the file can be previewed by the DICOM Viewer. */
export const isPreviewableFile = (file: unknown): boolean => {
  const type = getFileType(file)
  return (
    !nonPreviewableMimeTypes.includes(type as DropzoneMIMEType) &&
    (type.startsWith("image/") || type === DropzoneMIMEType.Dcm)
  )
}

/** Returns whether the file can be downloadable for inline preview from binary data (mostly images) */
const isDownloadableFile = (file: unknown): boolean => {
  const type = getFileType(file)
  return !nonPreviewableMimeTypes.includes(type as DropzoneMIMEType) && type.startsWith("image/")
}

type InitFilesStateProps<TSerializedFileType, TDeserializedFileType extends FileWithPath> = {
  headers: Record<string, string>
  jsonFiles: TSerializedFileType[]
  getDownloadUrl: (f: TSerializedFileType) => URL
  deserialize: (f: TSerializedFileType, b?: BlobPart[]) => TDeserializedFileType
  setFiles: (files: FileWithPath[]) => void
}

export const initFilesState = async <TSerializedFileType, TDeserializedFileType extends FileWithPath>({
  headers,
  jsonFiles,
  getDownloadUrl,
  deserialize,
  setFiles,
}: InitFilesStateProps<TSerializedFileType, TDeserializedFileType>): Promise<void> => {
  const promises: Promise<TDeserializedFileType>[] = []
  jsonFiles.forEach((f) => {
    const downloadUrl = getDownloadUrl(f)
    if (!downloadUrl) return
    let promise: Promise<TDeserializedFileType>
    if (isDownloadableFile(f)) {
      promise = fetch(downloadUrl, { method: "GET", headers: new Headers(headers) })
        .then((response) => response.blob())
        .then((blob) => deserialize(f, [blob]))
    } else {
      promise = Promise.resolve(deserialize(f))
    }
    promises.push(promise)
  })
  const files = await Promise.all(promises)
  setFiles(files)
}

type GetUseDropzoneCommonPropsProps = {
  uploadProps: UploadPropsInterface
  options: UploadOptionsInterface
  validations: DropzoneValidations
  translate: TranslationFn
}

type UseDropzoneCommonProps = Pick<
  UseDropzoneProps,
  | "descriptionLabel"
  | "buttonLabel"
  | "errorsLabel"
  | "helpLabel"
  | "errorMessages"
  | "validations"
  | "enableMIMETypeSniffing"
  | "displayFileSize"
  | "disabled"
  | "readOnly"
>

export const getUseDropzoneCommonProps = ({
  uploadProps,
  options,
  validations,
  translate,
}: GetUseDropzoneCommonPropsProps): UseDropzoneCommonProps => {
  return {
    descriptionLabel: translate("dropzone.descriptionLabel"),
    buttonLabel: translate("dropzone.buttonLabel"),
    errorsLabel: translate("dropzone.errorsLabel"),
    helpLabel:
      validations?.maxSizeBytes !== undefined &&
      validations?.maxFiles !== undefined &&
      translate("dropzone.helpLabel", {
        maxSize: validations.maxSizeBytes / 1_000_000,
        maxCount: validations.maxFiles,
      }),
    errorMessages: getErrorMessages(translate, validations),
    validations,
    disabled: uploadProps.isDisabled,
    readOnly: uploadProps.isReadOnly,
    enableMIMETypeSniffing: options.enableMIMETypeSniffing === true,
    displayFileSize: true,
  }
}

type GetFileActionsProps = {
  showUploadList: ShowUploadListType
  translate: TranslationFn
  onPreview: (file: FileWithPath) => void
  onDownload: (file: FileWithPath) => void
  onEditNote?: (file: FileWithPath) => void
  showPreview: (file: FileWithPath) => boolean
  showDownload: (file: FileWithPath) => boolean
  showEditNote?: (file: FileWithPath) => boolean
}

export const getFileActions = ({
  showUploadList,
  translate,
  onPreview,
  onDownload,
  onEditNote,
  showPreview,
  showDownload,
  showEditNote,
}: GetFileActionsProps): DropzonePreviewAction[] => {
  const actions: DropzonePreviewAction[] = []
  if (showUploadList === true || (typeof showUploadList === "object" && showUploadList.showDownloadIcon !== false)) {
    actions.push({
      actionKey: "download",
      icon: <IconWrapper name="Download" />,
      onClick: (file) => onDownload(file),
      shouldRenderAction: showDownload,
      ariaLabel: translate("dropzone.actions.download"),
    })
  }
  if (showUploadList === true || (typeof showUploadList === "object" && showUploadList.showPreviewIcon !== false)) {
    actions.push({
      actionKey: "preview",
      icon: <IconWrapper name="Eye" />,
      onClick: (file) => onPreview(file),
      shouldRenderAction: showPreview,
      ariaLabel: translate("dropzone.actions.preview"),
    })
  }

  if (showUploadList === true || (typeof showUploadList === "object" && showUploadList.showEditNoteIcon !== false)) {
    actions.push({
      actionKey: "editNote",
      icon: <IconWrapper name="Edit" />,
      onClick: (file) => onEditNote?.(file),
      shouldRenderAction: (file) => showEditNote?.(file) ?? false,
      ariaLabel: translate("dropzone.actions.editNote"),
    })
  }
  return actions
}

export const getErrorMessages = (translate: TranslationFn, validations: DropzoneValidations): DropzoneErrorMessages => {
  return {
    [DropzoneErrorCode.FileInvalidType]: (file) =>
      translate("dropzone.errorMessages.fileInvalidType", {
        fileName: file?.name,
      }),
    [DropzoneErrorCode.FileTooLarge]: (file) =>
      translate("dropzone.errorMessages.fileTooLarge", {
        fileName: file?.name,
        maxSize: `${(validations.maxSizeBytes ?? 0) / 1_000_000} ${translate("dropzone.units.MB")}`,
      }),
    [DropzoneErrorCode.FileTooSmall]: (file) =>
      translate("dropzone.errorMessages.fileTooSmall", {
        fileName: file?.name,
        minSize: `${(validations.minSizeBytes ?? 0) / 1_000} ${translate("dropzone.units.kB")}`,
      }),
    [DropzoneErrorCode.TooManyFiles]: () =>
      translate("dropzone.errorMessages.tooManyFiles", { count: validations.maxFiles }),
    [DropzoneErrorCode.TooFewFiles]: () =>
      translate("dropzone.errorMessages.tooFewFiles", { count: validations.minFiles }),
    [DropzoneErrorCode.SpoofedFileExtension]: (file) =>
      translate("dropzone.errorMessages.spoofedFileExtension", {
        fileName: file?.name,
      }),
  }
}

export const downloadFile = async (fileName: string, downloadUrl: URL, headers: Record<string, string>) => {
  try {
    const response = await fetch(downloadUrl, { method: "GET", headers: new Headers(headers) })
    const blob = await response.blob()
    const blobUrl = URL.createObjectURL(blob)
    const downloadLink = document.createElement("a")
    downloadLink.href = blobUrl
    downloadLink.download = fileName
    document.body.appendChild(downloadLink)
    downloadLink.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    )
    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(blobUrl)
  } catch (e) {
    console.error("File download failed", e) // eslint-disable-line no-console
  }
}
