import React, { useState, ReactNode, SyntheticEvent } from "react"
import { Tooltip } from "@akinoxsolutions/gerudo-ui/dist/Tooltip"
import { Textarea } from "@akinoxsolutions/gerudo-ui/dist/Input/"
import { LabeledInput } from "@akinoxsolutions/gerudo-ui/dist/LabeledInput"
import { Button, ButtonGroup, ButtonTypes } from "@akinoxsolutions/gerudo-ui/dist/Button"
import useTranslation from "../../../../hooks/useTranslation"
import { Attachment } from "../Attachment"
import { AttachmentNote } from "../types"

type EditNotePopoverProps = {
  visible: boolean
  file: Attachment
  children: ReactNode
  onClose: () => void
  onSubmit: (file: Attachment, note: AttachmentNote) => void
}

// TODO could make it configurable through the schema if needed?
const MAX_CHARACTERS = 150

export const EditNotePopover = ({ visible, file, children, onClose, onSubmit: onSubmitProp }: EditNotePopoverProps) => {
  const { translate } = useTranslation()

  const [note, setNote] = useState<AttachmentNote>(file.response.note)

  const onSubmit = (e: SyntheticEvent) => {
    e.stopPropagation()
    if (file) {
      onSubmitProp(file, note)
    }
  }

  const onCancel = (e: SyntheticEvent) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <Tooltip
      visible={visible}
      fixedWidth={420}
      placement="leftBottom"
      content={
        <form>
          <LabeledInput
            name="note"
            label={translate("dropzone.editNote.noteLabel")}
            help={translate("dropzone.editNote.noteHelp", { max: MAX_CHARACTERS })}
          >
            <Textarea
              value={note ?? ""}
              placeholder={translate("dropzone.editNote.notePlaceholder")}
              onChange={(e) => setNote(e.target.value)}
              autoSize={{ minRows: 3, maxRows: 3 }}
            />
          </LabeledInput>
          <ButtonGroup>
            <Button type={ButtonTypes.Default} onClick={onSubmit}>
              {translate("actions.save")}
            </Button>
            <Button type={ButtonTypes.Text} onClick={onCancel}>
              {translate("actions.cancel")}
            </Button>
          </ButtonGroup>
        </form>
      }
    >
      {children}
    </Tooltip>
  )
}
