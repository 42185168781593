"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotEmptyValidator = void 0;
const tslib_1 = require("tslib");
const AbstractValidator_1 = require("./AbstractValidator");
class NotEmptyValidator extends AbstractValidator_1.AbstractValidator {
    constructor(options, type) {
        super(type);
        this.options = options;
    }
    validate({ data }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const d = data;
            if (!d || (typeof d === "string" && d.trim().length === 0) || (Array.isArray(data) && d.length === 0)) {
                return this.formatResult([false, { code: "EMPTY_VALUE" }], this.options);
            }
            return [true, null];
        });
    }
}
exports.NotEmptyValidator = NotEmptyValidator;
