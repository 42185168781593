import { FileWithPath } from "@akinoxsolutions/gerudo-ui/dist/Dropzone"
import { AttachmentExtraDataInterface } from "../shared/types"

export type AttachmentNote = string | null

export interface AttachmentCreatedResponseInterface {
  id: string
  displayName: string
  downloadUrl: URL
  filePath: string | null
  createdOn: string
  note: AttachmentNote
  mimeType: string
  referenceId: string
  referenceType: string
  sectionId: string
  sizeInBytes: number | null
  status: number // Brigid backend enum
  thumbnailPath: string | null
  thumbnailUrl: string | null
  uploader: string
}

export interface AttachmentInterface {
  response: AttachmentCreatedResponseInterface
  extraData: AttachmentExtraDataInterface
  status?: SignalRAttachmentEvents
}

export type AttachmentSerializedDataInterface = AttachmentInterface &
  Pick<FileWithPath, "lastModified" | "name" | "size" | "type">

// From SignalRAttachmentEvents in brigid backend
export enum SignalRAttachmentEvents {
  UploadComplete = "UploadComplete",
  Available = "Available",
}
