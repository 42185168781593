import React, { useContext, useEffect, useState } from "react"
import { FormContext } from "../../context/form/formState"
import { UseFormActionInterface } from "../../context/form/useFormAction"
import { NavigationWarning } from "../NavigationWarning/NavigationWarning"
import { SubmitButton, CustomFormActions } from "../index"
import { AvailableInfoInterface } from "./interfaces"
import NextStepButton from "./NextStepButton/NextStepButton"
import PreviousStepButton from "./PreviousStepButton/PreviousStepButton"
import styles from "./StepNavigation.module.css"

interface CurrentSectionInfoInterface {
  sectionId?: string
  isFirstStep?: boolean
  isLastStep?: boolean
}

const StepNavigation = (): JSX.Element => {
  const {
    form,
    formOptions,
    formBuildOptions,
    formQualifications,
    formValidations,
    navigableSectionUids,
    stepIndex,
  }: UseFormActionInterface = useContext(FormContext)
  const [currentSectionInfo, setCurrentSectionInfo] = useState<CurrentSectionInfoInterface>({})
  const [availableInfo, setAvailableInfo] = useState<AvailableInfoInterface>({
    currentIndex: 0,
    currentSectionId: "",
    allowInvalidSection: false,
    isFirstStep: false,
    isLastStep: false,
    isSectionValid: false,
    sectionIds: [],
    isReadOnly: Boolean(formBuildOptions?.readOnlyMode),
  })

  useEffect(() => {
    const sectionId = navigableSectionUids[stepIndex]
    setCurrentSectionInfo({
      sectionId,
      isFirstStep: stepIndex === 0,
      isLastStep: stepIndex === navigableSectionUids.length - 1,
    })
  }, [formQualifications, navigableSectionUids, stepIndex])

  useEffect(() => {
    const sectionId = navigableSectionUids[stepIndex]
    const sectionIds = navigableSectionUids.filter((sectionId) => {
      return formQualifications[sectionId] || form?.schema?.search(sectionId)?.type === "null"
    })
    const currentIndex = sectionIds.indexOf(sectionId)
    const isFirstStep = currentIndex === 0
    const isLastStep = currentIndex === sectionIds.length - 1

    setAvailableInfo({
      currentIndex,
      currentSectionId: sectionId,
      allowInvalidSection: formOptions.allowInvalidSection,
      isFirstStep,
      isLastStep,
      isSectionValid: formValidations[sectionId],
      nextSectionId: isLastStep ? undefined : sectionIds[currentIndex + 1],
      previousSectionId: isFirstStep ? undefined : sectionIds[currentIndex - 1],
      sectionIds,
      isReadOnly: Boolean(formBuildOptions?.readOnlyMode),
    })
  }, [formQualifications, navigableSectionUids, stepIndex])

  const classNames = [styles.StepNavigation]
  if (currentSectionInfo.isLastStep) {
    classNames.push(styles.LastStep)
  }

  const showPreviousButton = !currentSectionInfo.isFirstStep && !currentSectionInfo.isLastStep

  return (
    <section id="step-navigation" className={classNames.join(" ")}>
      {showPreviousButton && (
        <>
          <NavigationWarning className={styles.NavigationWarning} />
          <PreviousStepButton availableInfo={availableInfo} />
        </>
      )}
      {currentSectionInfo.isLastStep ? (
        <>
          <SubmitButton />
          <CustomFormActions />
        </>
      ) : (
        <NextStepButton availableInfo={availableInfo} />
      )}
    </section>
  )
}

export default StepNavigation
