import React, { useContext, useEffect } from "react"
import useTranslation from "../../../hooks/useTranslation"

import {
  DropzoneValidations,
  FileWithPath,
  useDropzone,
  UseDropzoneProps,
  DropzoneErrorCode,
} from "@akinoxsolutions/gerudo-ui/dist/Dropzone"
import { AnswerableComponentPropsInterface } from "../../interfaces"
import { getPreviewableFileIds, isAttachment } from "./helpers"
import { AttachmentSerializedDataInterface, AttachmentUploadedResponseInterface } from "./types"
import { Attachment } from "./Attachment"
import { MessagingContext } from "../../../context/messaging/messagingState"
import { getPropertyUiOptions } from "../../../utils/propertyUtils"
import { UploadOptionsInterface } from "./../UploadOptionsInterface"
import {
  downloadFile,
  getFileActions,
  getUseDropzoneCommonProps,
  initFilesState,
  isPreviewableFile,
} from "../shared/helpers"
import { DropzonePreviewAction } from "../shared/types"

export interface UploadPropsInterface extends AnswerableComponentPropsInterface<AttachmentSerializedDataInterface[]> {}

const Upload = (props: UploadPropsInterface) => {
  const { onChange, property, value } = props

  const {
    def: { validations: { upload: { required = false, minItems = required ? 1 : 0, maxItems = 10 } = {} } = {} },
  } = property

  const uiOptions = getPropertyUiOptions<UploadOptionsInterface>(property)

  const {
    action: apiUrl = "",
    method = "POST",
    acceptedFiles = undefined,
    maxSizeBytes = undefined,
    minSizeBytes = undefined,
    showUploadList = true,
    uploadedBy = undefined,
    headers = {},
    data = {},
  } = uiOptions

  const { postPreviewFileMessage } = useContext(MessagingContext)

  const { translate } = useTranslation()

  useEffect(() => {
    if (!Array.isArray(value) || value.length === 0) return
    initFilesState<AttachmentSerializedDataInterface, Attachment>({
      headers,
      jsonFiles: value,
      getDownloadUrl: (f) => f.response[0].downloadUrl ?? new URL(""),
      deserialize: Attachment.fromJSON,
      setFiles: setNewFilesState,
    })
  }, [])

  const validations: DropzoneValidations = {
    accept: acceptedFiles,
    maxSizeBytes,
    minSizeBytes,
    minFiles: minItems,
    maxFiles: maxItems,
  }

  const renderPreviewInfo = (file: FileWithPath) => {
    if (isAttachment(file) && file.extraData?.uploadedBy) {
      return translate("dropzone.uploadedBy", {
        name: file.extraData?.uploadedBy,
        date: new Date(file.extraData?.uploadedAt).toLocaleString(),
      })
    }
    return null
  }

  const onDownloadFile = async (file: FileWithPath) => {
    if (!isAttachment(file)) return
    const fileName = file.name
    const downloadUrl = file.response[0].downloadUrl
    if (!downloadUrl) return
    downloadFile(fileName, downloadUrl, headers)
  }

  const onPreviewFile = (file: FileWithPath) => {
    if (!isAttachment(file) || !file.response[0].fileId) return
    postPreviewFileMessage(file, file.response[0].fileId, getPreviewableFileIds(dropzoneFiles))
  }

  const fileActions: DropzonePreviewAction[] = getFileActions({
    showUploadList,
    translate,
    onPreview: onPreviewFile,
    onDownload: onDownloadFile,
    showPreview: (f) => isPreviewableFile(f) && isAttachment(f),
    showDownload: (f) => isAttachment(f),
  })

  const dropzoneProps: UseDropzoneProps = {
    ...getUseDropzoneCommonProps({
      uploadProps: props,
      options: uiOptions,
      validations,
      translate,
    }),
    onRemoveFile: (file) => onDeleteFile(file),
    onAddFiles: (files) => onAddFiles(files),
    hidePreviewInfos: showUploadList === false,
    actions: fileActions,
    renderPreviewInfo,
    "data-testid": "legacyAttachments-dropzone",
  }

  const { Dropzone, files: dropzoneFiles, setFiles, setErrors } = useDropzone(dropzoneProps)

  // Since Gerudo Dropzone's inner react-dropzone dependency expects File objects and
  // Dynamic Form's data expects something serializable (which a File is not) we have to
  // handle both types of data
  const setNewFilesState = (files: FileWithPath[]) => {
    const newUploadedFiles = files.filter(isAttachment)
    setFiles(newUploadedFiles)
    onChange(newUploadedFiles.map((f) => f.toJSON()))

    if (newUploadedFiles.length < validations.minFiles) {
      setErrors([{ code: DropzoneErrorCode.TooFewFiles, isStateError: true }])
    }
  }

  const onAddFiles = async (files: FileWithPath[]) => {
    const newFiles = [...dropzoneFiles]
    const requestBody = new FormData()
    for (const [key, val] of Object.entries(data)) {
      requestBody.append(key, val)
    }
    files.forEach((f) => {
      requestBody.append("files", f)
    })
    try {
      const response = await fetch(apiUrl, { method, body: requestBody, headers: new Headers(headers) })
      const fileResponses = (await response.json()) as AttachmentUploadedResponseInterface[]
      // Map each file with its server response
      fileResponses.forEach((r, i) => {
        if (!r.errorCode) {
          const f = files[i]
          const a = new Attachment(f, r, { uploadedBy, uploadedAt: new Date().toISOString() })
          newFiles.push(a)
        }
      })
    } catch (e) {
      console.error("File upload failed", e) // eslint-disable-line no-console
    } finally {
      setNewFilesState(newFiles)
    }
  }

  const onDeleteFile = async (file?: FileWithPath) => {
    if (!file) return
    const newFiles = [...dropzoneFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setNewFilesState(newFiles)
    if (!isAttachment(file)) return
    const deleteUrl = file.response[0].deleteUrl
    if (!deleteUrl) return
    try {
      await fetch(deleteUrl, { method: "DELETE", headers: new Headers(headers) })
    } catch (e) {
      console.error("File deletion failed", e) // eslint-disable-line no-console
    }
  }

  return <Dropzone />
}

export default Upload
