import React, { ReactNode, useContext } from "react"
import useTranslation from "../../hooks/useTranslation"
import { ConfigContext } from "../../context/config/configState"
import { FormContext } from "../../context/form/formState"
import { MessagingContext } from "../../context/messaging/messagingState"
import { getComponentName, getStyle, getSubType } from "../../utils/uiUtils"
import { CustomEventsService } from "../../services/CustomEventsService"
import { Array } from "../Array/Array"
import { BodyPartPicker } from "../BodyPartPicker/BodyPartPicker"
import { DatePicker } from "../DatePicker/DatePicker"
import { Image } from "../Image/Image"
import { Input } from "../Input/Input"
import { InputMask } from "../Input/InputMask/InputMask"
import { InputNumber } from "../Input/InputNumber"
import LinkUiProperty from "../Link/LinkUiProperty"
import { Modal } from "../Modal/Modal"
import { Properties } from "../Properties/Properties"
import { Select } from "../Select/Select"
import { Status } from "../Status/Status"
import { Table } from "../Table/Table"
import { TimePicker } from "../TimePicker/TimePicker"
import { Unsupported } from "../Unsupported/Unsupported"
import {
  Answerable,
  Button,
  Checkbox,
  CheckboxGroup,
  DisplayItem,
  HtmlElement,
  Link,
  Message,
  NotificationBar,
  RadioCardGroup,
  RadioGroup,
  Tile,
  SearchableRadioCard,
  Switch,
  Upload,
  LegacyUpload,
} from "../index"
import styles from "./Property.module.css"
import { PropertyWrapper } from "./PropertyWrapper"
import { FormInterface, PropertyInterface } from "@akinoxsolutions/formol"
import { PropertyName, PropertySemanticType } from "./types"
import { propertyNames, semanticTypeMap } from "./constants"

interface PropertyPropsInterface {
  className?: string
  isDivided?: boolean
  parentIsDisabled?: boolean
  parentIsReadOnly?: boolean
  property: PropertyInterface
  title?: string
}

export const Property = ({
  className,
  isDivided,
  parentIsDisabled,
  parentIsReadOnly,
  property,
  title,
}: PropertyPropsInterface) => {
  const { translate } = useTranslation()
  const { description, title: propertyTitle, ui = {} } = property.def
  const { customEvents = [], disabled, options: { hidden: isHidden = false } = {}, readOnly, type: uiType } = ui

  const { readOnlyMode } = useContext(ConfigContext)

  const {
    form,
    formQualifications: { [property.UID]: qualified },
    formQualificationsErrors: { [property.UID]: qualificationErrors },
    updateData,
  } = useContext(FormContext)

  const { postCustomEvent } = useContext(MessagingContext)

  const isQualified = qualified || qualificationErrors?.behavior?.toLowerCase() === "disabled"
  const isDisabled = parentIsDisabled || disabled || qualificationErrors?.behavior?.toLowerCase() === "disabled"
  const isReadOnly = parentIsReadOnly || readOnly || readOnlyMode

  const onClick = new CustomEventsService(form as FormInterface, customEvents).getEventHandler(
    "onClick",
    postCustomEvent,
  )

  let isAnswerable = false

  const buildAnswerableComponent = (Component) => {
    isAnswerable = true
    return (
      <Answerable
        Component={Component}
        data={(form as FormInterface).dataSelector.get(property.UID)}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        property={property}
        updateData={updateData}
      />
    )
  }

  const classNames = className ? [className] : []

  let propertyName: PropertyName | null = null
  let component: ReactNode

  const componentName = getComponentName(uiType)

  if (componentName) {
    propertyName = componentName
  } else if (propertyNames.includes(property.type as PropertyName)) {
    propertyName = property.type as PropertyName
  }

  switch (propertyName) {
    /** Resolution by component name first */
    case "bodypartpicker":
      component = buildAnswerableComponent(BodyPartPicker)
      break
    case "button":
      if (!isQualified) {
        return null
      }
      return (
        <Button
          disabled={disabled || readOnlyMode}
          id={property.UID}
          label={title ?? translate(propertyTitle)}
          onClick={onClick}
          property={property}
        />
      )
    case "checkbox":
      component = buildAnswerableComponent(Checkbox)
      break
    case "checkboxes":
      component = buildAnswerableComponent(CheckboxGroup)
      break
    case "datepicker":
      component = buildAnswerableComponent(DatePicker)
      break
    case "displayitem":
      component = <DisplayItem property={property} />
      break
    case "hidden":
      return null
    case "html":
    case "htmlelement":
      component = <HtmlElement property={property} />
      classNames.push(styles.HtmlElement)
      break
    case "image":
      component = <Image id={property.UID} {...property.def} />
      break
    case "link":
      component = <Link disabled={isDisabled || isReadOnly} {...new LinkUiProperty(property, { onClick }).toProps()} />
      break
    case "mask":
      component = buildAnswerableComponent(InputMask)
      break
    case "message":
      if (!isQualified) {
        return null
      }
      return (
        <Message
          description={translate(description)}
          message={translate(propertyTitle)}
          type={getSubType(uiType)}
          style={getStyle(ui)}
          {...ui?.messageOptions}
        />
      )
    case "modal":
      return <Modal property={property} />
    case "notificationbar":
      component = <NotificationBar property={property} />
      break
    case "radio":
      component = buildAnswerableComponent(RadioGroup)
      break
    case "radiocard":
      component = buildAnswerableComponent(RadioCardGroup)
      break
    case "searchableradiocard":
      component = buildAnswerableComponent(SearchableRadioCard)
      break
    case "select":
      component = buildAnswerableComponent(Select)
      break
    case "status":
      component = <Status property={property} />
      break
    case "switch":
      component = buildAnswerableComponent(Switch)
      break
    case "table":
      component = <Table isDisabled={isDisabled} isReadOnly={isReadOnly} property={property} />
      break
    case "textarea":
    case "textfield":
      component = buildAnswerableComponent(Input)
      break
    case "tile":
      component = <Tile property={property} />
      break
    case "timepicker":
      component = buildAnswerableComponent(TimePicker)
      break
    case "legacyupload":
      component = buildAnswerableComponent(LegacyUpload)
      break
    case "upload":
      component = buildAnswerableComponent(Upload)
      break

    /** Resolution by property type if can't resolve by component */
    case "array":
      component = (
        <Array
          onChange={updateData}
          parentIsDisabled={parentIsDisabled}
          parentIsReadOnly={parentIsReadOnly}
          property={property}
        />
      )
      break
    case "number":
    case "integer":
      component = buildAnswerableComponent(InputNumber)
      break
    case "string":
      component = buildAnswerableComponent(Input)
      break
    case "object":
      component = (
        <Properties parentIsDisabled={parentIsDisabled} parentIsReadOnly={parentIsReadOnly} property={property} />
      )
      break
    default:
      component = (
        <Unsupported name={componentName ? `${property.type} / ${componentName}` : property.type} uid={property.UID} />
      )
  }

  if (!isQualified || isHidden) {
    return null
  }

  return (
    <PropertyWrapper
      className={classNames.join(" ")}
      isAnswerable={isAnswerable}
      isDisabled={isDisabled}
      isDivided={isDivided}
      isReadOnly={isReadOnly}
      property={property}
      semanticType={propertyName ? semanticTypeMap[propertyName] : PropertySemanticType.Display}
      title={title}
    >
      {component}
    </PropertyWrapper>
  )
}
