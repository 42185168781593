import React, { ReactNode } from "react"

import { DropzonePreview } from "@akinoxsolutions/gerudo-ui/dist/Dropzone/DropzonePreview"
import { Attachment } from "./Attachment"
import { DropzonePreviewAction } from "../shared/types"
import { EditNotePopover } from "./EditNotePopover"
import { AttachmentNote } from "./types"

interface AttachmentListPropsInterface {
  files: Attachment[]
  isDeletable: boolean
  editNoteFileId: string | undefined
  actions: DropzonePreviewAction<Attachment>[]
  onRemovePreview: (file: Attachment) => void
  renderPreviewInfo: (file: Attachment) => ReactNode
  onEditNoteSubmit: (file: Attachment, note: AttachmentNote) => void
  closeEditNotePopover: () => void
}

const AttachmentList = ({
  files,
  isDeletable,
  editNoteFileId,
  actions: actionsProp,
  onRemovePreview,
  renderPreviewInfo,
  onEditNoteSubmit,
  closeEditNotePopover,
}: AttachmentListPropsInterface) => {
  return (
    <div data-testid="attachments-dropzone-list">
      {files.map((file) => {
        const actions = actionsProp.map((action) => ({ ...action }))
        const editNoteAction = actions.find(({ actionKey }) => actionKey === "editNote")
        const isEditNotePopoverVisible = file.response.id === editNoteFileId

        if (editNoteAction && isEditNotePopoverVisible) {
          // Wrap the editNote action's icon with the EditNotePopover so that the
          // popover's position is relative to the icon which is its trigger
          editNoteAction.icon = (
            <EditNotePopover file={file} visible onClose={closeEditNotePopover} onSubmit={onEditNoteSubmit}>
              {editNoteAction.icon}
            </EditNotePopover>
          )
        }

        return (
          <DropzonePreview<Attachment>
            key={file.response.id}
            onRemovePreview={onRemovePreview}
            file={file}
            loadingProgress={file.progress}
            isDeletable={isDeletable}
            renderPreviewInfo={renderPreviewInfo}
            actions={actions}
          />
        )
      })}
    </div>
  )
}

export default AttachmentList
