import { FileWithPath } from "@akinoxsolutions/gerudo-ui/dist/Dropzone"
import {
  AttachmentSerializedDataInterface,
  AttachmentInterface,
  AttachmentCreatedResponseInterface,
  SignalRAttachmentEvents,
} from "./types"
import { AttachmentBaseInterface, AttachmentExtraDataInterface } from "../shared/types"

// Gerudo Dropzone uses native File objects which cannot be serialized.
// Dynamic Form needs to save the uploaded file in serialized format in the form's data
// Therefore we need to handle both data types and be able to convert between them
export class Attachment extends File implements AttachmentBaseInterface, AttachmentInterface {
  public path?: string
  public response: AttachmentCreatedResponseInterface
  public extraData: AttachmentExtraDataInterface
  public progress?: number
  public status?: SignalRAttachmentEvents

  // In TestCafé, for some weird reason testcafe-hammerhead overrides the prototype of the Attachment class
  // by the native File prototype. We need to define these class methods as object properties to prevent them
  // being erased from the prototype
  public setProgress: (progress: number) => void
  public setUploadStatus: (status?: SignalRAttachmentEvents) => void
  public setNote: (note: string | null) => void
  public toJSON: () => AttachmentSerializedDataInterface

  constructor(
    file: FileWithPath,
    response: AttachmentCreatedResponseInterface,
    extraData: AttachmentExtraDataInterface,
  ) {
    super([file], file.name, { type: response.mimeType, lastModified: file.lastModified })
    this.path = file.path
    this.response = response
    this.extraData = extraData
    this.setProgress = setProgress.bind(null, this)
    this.setUploadStatus = setUploadStatus.bind(null, this)
    this.setNote = setNote.bind(null, this)
    this.toJSON = toJSON.bind(null, this)
  }

  static fromJSON(file: AttachmentSerializedDataInterface, blobParts: BlobPart[] = []): Attachment {
    const f = new File(blobParts, file.name, { ...file })
    const a = new Attachment(f, file.response, file.extraData)
    a.setUploadStatus(file.status)
    return Object.defineProperty(a, "size", { value: file.response.sizeInBytes ?? 0 })
  }
}

const setProgress = (a: Attachment, progress: number) => {
  a.progress = Math.min(progress, 99)
}

const setUploadStatus = (a: Attachment, status?: SignalRAttachmentEvents) => {
  a.status = status
  if (status === SignalRAttachmentEvents.Available) {
    a.progress = 100
  }
}

const setNote = (a: Attachment, note: string | null) => {
  a.response.note = note
}

const toJSON = (a: Attachment): AttachmentSerializedDataInterface => {
  return {
    lastModified: a.lastModified,
    name: a.name,
    size: a.size,
    type: a.type,
    response: a.response,
    extraData: a.extraData,
    status: a.status,
  }
}
