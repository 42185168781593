import { FileWithPath } from "@akinoxsolutions/gerudo-ui/dist/Dropzone"

import { Attachment } from "./Attachment"
import { isPreviewableFile } from "../shared/helpers"

export const isAttachment = (file?: FileWithPath | Attachment): file is Attachment => {
  // response array is for used to identify legacyAttachments
  return (file as Attachment)?.response !== undefined && Array.isArray((file as Attachment).response)
}

export const getPreviewableFileIds = (files: FileWithPath[]): string[] => {
  return files
    .filter((f) => isAttachment(f) && isPreviewableFile(f))
    .map((f) => (f as Attachment).response[0].fileId)
    .filter(Boolean) as string[]
}
