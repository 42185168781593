import React from "react"
import { IdentificationCardIcon } from "@akinoxsolutions/gerudo-ui/dist/Icons/IdentificationCard"
import { IconSizes } from "@akinoxsolutions/gerudo-ui/dist/Icons/PropTypes/IconSizes"
import {
  AbortionIcon,
  ChronicalSicknessIcon,
  ContraceptionIcon,
  FamilyIcon,
  FeverIcon,
  FormsIcon,
  InfertilityIcon,
  MedicationIcon,
  MentalHealthIcon,
  MinorConditionIcon,
  NoTobaccoIcon,
  OtherHealthServicesIcon,
  PediatricsIcon,
  PregnancyIcon,
  ReevaluationIcon,
  SamplesIcon,
  ScreeningIcon,
  SexualHealthIcon,
  VaccinationIcon,
  InjuriesIcon,
  OtherPediatricsIcon,
  OtherChronicSicknessIcon,
  InfectionsIcon,
} from "@akinoxsolutions/gerudo-ui"

type RadioCardGroupIconsProps = {
  name: string
  props?: Record<string, unknown>
}

export const RadioCardGroupIcons = ({ name, props }: RadioCardGroupIconsProps): JSX.Element | null => {
  if (!name) return null

  switch (name.toLowerCase()) {
    case "abortion":
      return <AbortionIcon size={IconSizes.Large} {...props} />
    case "contraception":
      return <ContraceptionIcon size={IconSizes.Large} {...props} />
    case "infertility":
      return <InfertilityIcon size={IconSizes.Large} {...props} />
    case "identificationcard":
      return <IdentificationCardIcon size={IconSizes.Large} {...props} />
    case "bloodtest":
      return <SamplesIcon size={IconSizes.Large} {...props} />
    case "family":
      return <FamilyIcon size={IconSizes.Large} {...props} />
    case "flu":
      return <FeverIcon size={IconSizes.Large} {...props} />
    case "form":
      return <FormsIcon size={IconSizes.Large} {...props} />
    case "mentalhealth":
      return <MentalHealthIcon size={IconSizes.Large} {...props} />
    case "other":
      return <OtherHealthServicesIcon size={IconSizes.Large} {...props} />
    case "pediatrics":
      return <PediatricsIcon size={IconSizes.Large} {...props} />
    case "pregnancy":
      return <PregnancyIcon size={IconSizes.Large} {...props} />
    case "prescription":
      return <MedicationIcon size={IconSizes.Large} {...props} />
    case "sexualhealth":
      return <SexualHealthIcon size={IconSizes.Large} {...props} />
    case "vaccination":
      return <VaccinationIcon size={IconSizes.Large} {...props} />
    case "minorcondition":
      return <MinorConditionIcon size={IconSizes.Large} {...props} />
    case "reevaluation":
      return <ReevaluationIcon size={IconSizes.Large} {...props} />
    case "screening":
      return <ScreeningIcon size={IconSizes.Large} {...props} />
    case "chronicalsickness":
      return <ChronicalSicknessIcon size={IconSizes.Large} {...props} />
    case "notobacco":
      return <NoTobaccoIcon size={IconSizes.Large} {...props} />
    case "injuries":
      return <InjuriesIcon size={IconSizes.Large} {...props} />
    case "otherpediatrics":
      return <OtherPediatricsIcon size={IconSizes.Large} {...props} />
    case "otherchronicsickness":
      return <OtherChronicSicknessIcon size={IconSizes.Large} {...props} />
    case "infections":
      return <InfectionsIcon size={IconSizes.Large} {...props} />
    default:
      return null
  }
}
